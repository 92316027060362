<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="12" class="pb-0 py-0">
              <v-text-field
                v-model.trim="fPagoNom"
                label="Nombre"
                outlined
                clearable
                dense
                :rules="
                  rules.required.concat([
                    rules.maxLength(fPagoNom, 30),
                    rules.requiredTrim(fPagoNom)
                  ])
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="py-0 py-0">
              <v-switch
                v-model="pedirEntidad"
                :disabled="!isEntidad"
                label="Entidad requerida"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="py-0 py-0">
              <v-switch
                v-model="esCheque"
                :disabled="!isCheque"
                label="Es cheque de tercero"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="7" class="py-0 py-0">
              <v-switch
                v-model="transBanco"
                :disabled="!isTrans"
                label="Es transferencia bancaria"
              ></v-switch>
            </v-col>
          </v-row>
          <v-col cols="12" class="py-0 py-0">
            <template v-if="transBanco" class="py-0">
              <v-autocomplete
                outlined
                clearable
                dense
                :items="ctasBancarias"
                item-text="value"
                label="Cuenta bancaria"
                v-model="ctaBancaria"
                :rules="rules.required"
              ></v-autocomplete>
              <v-autocomplete
                outlined
                clearable
                dense
                :items="tiposMovBancarios"
                item-text="value"
                label="Tipo de movimiento"
                v-model="tipoMovBancario"
                :rules="rules.required"
              ></v-autocomplete>
            </template>
          </v-col>
          <v-row>
            <v-col cols="8" class="py-0 py-0">
              <v-switch
                v-model="excluirGeneracionCupon"
                label="Excluir generación de cupón"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="py-0 py-0">
              <v-switch
                v-model="esDisponibilidad"
                label="Es disponibilidad"
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";

export default {
  name: "EditFormaDePago",
  props: {
    fPago: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    isFormValid: false,
    isTrans: true,
    isCheque: true,
    isEntidad: true,
    formEditTitle: "Editar forma de pago",
    title: enums.titles.FORMAS_PAGO,
    rules: rules,
    nomCuentaBanc: "",
    model: null,
    tiposMovBancarios: [],
    tipoMovBancario: null,
    ctasBancarias: [],
    ctaBancaria: null,
    fPagoNom: null,
    pedirEntidad: false,
    esCheque: false,
    transBanco: false,
    excluirGeneracionCupon: false,
    esDisponibilidad: false
  }),
  created() {
    if (this.fPago != null) {
      this.setFormaPago(this.fPago);
    } else {
      this.newFormaDePago();
    }
    this.loadFormasDePago();
  },
  computed: {
    tipoMovBancComputed() {
      return this.tipoMovBancario;
    }
  },
  watch: {
    pedirEntidad() {
      if (this.pedirEntidad) {
        this.isTrans = false;
        this.isCheque = false;
      } else {
        if (this.esCheque) {
          this.isEntidad = false;
        } else {
          if (this.transBanco) {
            this.isEntidad = false;
          } else {
            this.isTrans = true;
            this.isCheque = true;
            this.isEntidad = true;
          }
        }
      }
    },
    esCheque() {
      if (this.esCheque) {
        this.isTrans = false;
        this.isEntidad = false;
      } else {
        if (this.pedirEntidad) {
          this.isCheque = false;
        } else {
          if (this.transBanco) {
            this.isCheque = false;
          } else {
            this.isTrans = true;
            this.isCheque = true;
            this.isEntidad = true;
          }
        }
      }
    },
    transBanco() {
      if (this.transBanco) {
        this.isCheque = false;
        this.isEntidad = false;
      } else {
        if (this.pedirEntidad) {
          this.isTrans = false;
        } else {
          if (this.esCheque) {
            this.isTrans = false;
          } else {
            this.isTrans = true;
            this.isCheque = true;
            this.isEntidad = true;
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({
      getCuentaBancarias: "configuracion/getCuentaBancarias",
      getTipoMovBancarios: "configuracion/getTipoMovBancarios",
      getConfiguracionFormasDePagoById:
        "configuracion/getConfiguracionFormasDePagoById",
      postConfiguracionFormasDePago:
        "configuracion/postConfiguracionFormasDePago",
      setAlert: "user/setAlert"
    }),
    async loadFormasDePago() {
      const data2 = await this.getTipoMovBancarios();
      this.tiposMovBancarios = data2;
      const data3 = await this.getCuentaBancarias();
      this.ctasBancarias = data3;
    },
    async setFormaPago(fPago) {
      const dataMovimientos = await this.getTipoMovBancarios();
      this.tiposMovBancarios = dataMovimientos;
      const object1 = await this.tiposMovBancarios;

      let idValueMovimientos = 0;

      for (const [index, k] of Object.entries(object1)) {
        if (k.id === fPago.tmbIdIng) {
          idValueMovimientos = k.value;
        }
      }

      const dataCtasBancarias = await this.getCuentaBancarias();
      this.ctasBancarias = dataCtasBancarias;
      const object2 = await this.ctasBancarias;

      let idValueCtasBancarias = "";

      for (const [index, k] of Object.entries(object2)) {
        if (k.id === fPago.bctaId) {
          idValueCtasBancarias = k.value;
        }
      }
      this.fPagoNom = fPago.fPagoNom;
      this.pedirEntidad = fPago.pedirEntidad;
      this.esCheque = fPago.esCheque;
      this.transBanco = fPago.transBanco;
      this.bctaId = fPago.bctaId;
      this.tipoMovBancario = idValueMovimientos;
      this.ctaBancaria = idValueCtasBancarias;
      this.excluirGeneracionCupon = fPago.excluirGeneracionCupon;
      this.tmbIdIng = fPago.tmbIdIng;
      this.transBanco = fPago.transBanco;
      this.fPagoId = fPago.fPagoId;
      this.esDisponibilidad = fPago.esDisponibilidad;
    },
    async newFormaDePago() {
      this.formEditTitle = "Nueva forma de pago";
    },
    async saveEdit() {
      const dataMovimientos = await this.getTipoMovBancarios();
      this.tiposMovBancarios = dataMovimientos;
      const object1 = await this.tiposMovBancarios;

      let idValueMovimientos = 0;

      for (const [index, k] of Object.entries(object1)) {
        if (k.value === this.tipoMovBancario) {
          idValueMovimientos = k.id;
        }
      }

      //////

      const dataCtasBancarias = await this.getCuentaBancarias();
      this.ctasBancarias = dataCtasBancarias;
      const object2 = await this.ctasBancarias;

      let idValueCtasBancarias = 0;

      for (const [index, k] of Object.entries(object2)) {
        if (k.value === this.ctaBancaria) {
          idValueCtasBancarias = k.id;
        }
      }

      let data = {};

      if (this.transBanco) {
        data = {
          bctaId: idValueCtasBancarias,
          esCheque: this.esCheque,
          excluirGeneracionCupon: this.excluirGeneracionCupon,
          fPagoId: this.fPagoId,
          fPagoNom: this.fPagoNom,
          pedirEntidad: this.pedirEntidad,
          tmbIdIng: idValueMovimientos,
          transBanco: this.transBanco,
          esDisponibilidad: this.esDisponibilidad
        };
      } else {
        data = {
          esCheque: this.esCheque,
          excluirGeneracionCupon: this.excluirGeneracionCupon,
          fPagoId: this.fPagoId,
          fPagoNom: this.fPagoNom,
          pedirEntidad: this.pedirEntidad,
          esDisponibilidad: this.esDisponibilidad,
          transBanco: this.transBanco
        };
      }

      const res = await this.postConfiguracionFormasDePago(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
::v-deep label {
  margin-bottom: 0;
}
</style>
