<template>
  <v-container fluid>
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <PageHeader :title="title" />
    <v-data-table
      :headers="headers"
      :items="configFormasDePago"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:[`item.pedirEntidad`]="{ item }">
        <v-icon
          v-if="item.pedirEntidad"
          small
          color="primary"
          class="d-flex justify-center"
        >
          {{ checkIcon }}
        </v-icon>
      </template>
      <template v-slot:[`item.esCheque`]="{ item }">
        <v-icon
          v-if="item.esCheque"
          small
          color="primary"
          class="d-flex justify-center"
        >
          {{ checkIcon }}
        </v-icon>
      </template>
      <template v-slot:[`item.transBanco`]="{ item }">
        <v-icon
          v-if="item.transBanco"
          small
          color="primary"
          class="d-flex justify-center"
        >
          {{ checkIcon }}
        </v-icon>
      </template>
      <template v-slot:[`item.excluirGeneracionCupon`]="{ item }">
        <v-icon
          v-if="item.excluirGeneracionCupon"
          small
          color="primary"
          class="d-flex justify-center"
        >
          {{ checkIcon }}
        </v-icon>
      </template>
      <template v-slot:[`item.esDisponibilidad`]="{ item }">
        <v-icon
          v-if="item.esDisponibilidad"
          small
          color="primary"
          class="d-flex justify-center"
        >
          {{ checkIcon }}
        </v-icon>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="10">
              <v-text-field
                v-model="search"
                :append-icon="searchIcon"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="2" align="end" v-if="canCreate">
              <v-btn color="primary" @click="openModal()" class="to-right">
                Nuevo
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left v-if="canEdit">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" size="20" v-on="on" @click="openModal(item)">
              {{ editIcon }}
            </v-icon>
          </template>
          <span>Editar forma de pago</span>
        </v-tooltip>
        <v-tooltip left v-if="canDelete && item.fPagoId != 1">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="20"
              v-on="on"
              @click="deleteFormaDePago(item.fPagoId)"
            >
              {{ deleteIcon }}
            </v-icon>
          </template>
          <span>Eliminar forma de pago</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="500px"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditFormaDePago
        :fPago="fPago"
        @closeAndReload="closeAndReload"
      ></EditFormaDePago>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
import DeleteDialog from "@/components/shared/DeleteDialog";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import EditFormaDePago from "@/components/modules/cuotas/configuracion/EditFormaDePago.vue";

export default {
  name: "FormasPago",
  components: { PageHeader, GoBackBtn, DeleteDialog, EditFormaDePago },

  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    checkIcon: enums.icons.CHECK_OUTLINE,
    showDeleteModal: false,
    search: "",
    searchIcon: enums.icons.SEARCH,
    idToDelete: null,
    tiposMovBancarios: [],
    routeToGo: "FormasPagoYEntidadesCobranza",
    title: enums.titles.FORMAS_PAGO,
    titleDelete: "¿Eliminar?",
    rules: rules,
    configFormasDePago: [],
    headers: [
      {
        text: "Nombre",
        align: "start",
        value: "fPagoNom",
        sortable: false
      },
      {
        text: "Entidad requerida",
        value: "pedirEntidad",
        align: "center",
        sortable: false
      },
      {
        text: "Es cheque de tercero",
        value: "esCheque",
        align: "center",
        sortable: false
      },
      {
        text: "Es transferencia bancaria",
        value: "transBanco",
        align: "center",
        sortable: false
      },
      {
        text: "Cuenta bancaria",
        value: "bctaNom",
        sortable: false
      },
      {
        text: "Movimiento bancario",
        value: "tmbNomIng",
        sortable: false
      },
      {
        text: "Excluir de generación de cupón",
        value: "excluirGeneracionCupon",
        align: "center",
        sortable: false
      },
      {
        text: "Disponibilidad",
        value: "esDisponibilidad",
        align: "center",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,

    canEdit: false,
    canDelete: false,
    canCreate: false,
    openModalEdit: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadFormasDePago();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      deleteConfiguracionFormasDePago:
        "configuracion/deleteConfiguracionFormasDePago",
      getTipoMovBancarios: "configuracion/getTipoMovBancarios",
      getConfiguracionFormasDePago:
        "configuracion/getConfiguracionFormasDePago",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ELIMINAR_FORMA_PAGO:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_FORMA_PAGO:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVA_FORMA_PAGO:
            this.canCreate = true;
            break;
          default:
            break;
        }
      });
    },
    async loadFormasDePago() {
      const response = await this.getConfiguracionFormasDePago();
      this.configFormasDePago = response;
      const data2 = await this.getTipoMovBancarios();
      this.tiposMovBancarios = data2;
    },
    deleteFormaDePago(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteConfiguracionFormasDePago({
        fPagoId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadFormasDePago();
      }
    },
    openModal(fPago) {
      this.openModalEdit = true;
      this.fPago = fPago;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadFormasDePago();
    }
  }
};
</script>

<style scoped></style>
